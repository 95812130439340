import React from "react";
import "../styles/Projects.css";
import FolderOpenRoundedIcon from "@material-ui/icons/FolderOpenRounded";
import FadeInSection from "./FadeInSection";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from "react-bootstrap/Carousel";
import ExternalLinks from "./ExternalLinks";

class Projects extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1",
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey,
    });
  }
  render() {
    const spotlightProjects = {
      "Estate Ease": {
        title: "Estate Ease",
        desc:
          "A web3 alternative to MagicBricks built with Solidity smart contracts. It connects buyer, seller, escrow in a crypto ecosystem.",
        techStack: "Solidity, react, js",
        link: "https://github.com/mkbhru/estate-ease",
        open: "https://github.com/mkbhru/estate-ease",
        image: "/assets/estate_ease.jpg",
      },
      Truth: {
        title: "truth",
        desc:
          "A three.js simulation of the planet system revolving around a monolith.",
        techStack: "JAVASCRIPT (THREE.JS)",
        link: "https://github.com/mkbhru/truth",
        open: "https://mkbhru.github.io/truth/",
        image: "/assets/truth.png",
      },
      // "Tall Tales": {
      //   title: "tall tales",
      //   desc:
      //     "A multi-player story-telling web game for 3-5 players. Its usage of sockets to allow for concurrent gameplay, connecting friends across the internet.",
      //   techStack: "NODE.JS (SOCKET.IO), REACT.JS, MONGODB",
      //   link: "https://github.com/mkbhru/TallTales",
      //   open: "https://talltales.herokuapp.com/",
      //   image: "/assets/talltales.png",
      // },
      // Portfolio: {
      //   title: "portfolio.js",
      //   desc:
      //     "A small JS library that helps with clear and succinct data presentation.",
      //   techStack: "NODE.JS (EXPRESS.JS)",
      //   link: "https://github.com/mkbhru/Portfolio.js",
      //   open: "https://afternoon-ocean-92382.herokuapp.com/",
      //   image: "/assets/portfolio.png",
      // },
    };
    let projects = {}
    if (window.innerWidth <= 999) {
      projects = {
        ...spotlightProjects
      };
    }
    projects = {
      ...projects,
      "Drum-kit": {
        desc:
          "A Drum-Kit web app to play with sounds produced by a drum virtually.",
        techStack: "Html, Css, Javascript",
        link: "https://github.com/mkbhru/drum-kit",
        open: "https://mkbhru.github.io/drum-kit/",
      },
      "File Sharing Dapp": {
        desc:
          "A decentralized application to share and host files on ipfs network. Basically a web3 clone of gdrive.",
        techStack: "Solidity, Javascript, Html, Css",
        link: "hhttps://github.com/mkbhru/file-sharing-dapp",
        // open: "https://tdsb-app.herokuapp.com/",
      },
      "Hostel Complain System": {
        desc:
          "A real life solution for managing day to day complains in our hostel as a team project with my friends and Dr. Mohit as mentor.",
        techStack: "Javascript, HTML / CSS",
        link: "https://github.com/mkbhru/hostel-complain-system",
        // open: "https://mkbhru.github.io/AdamAI/",
      },
      "MI Store Clone": {
        desc: "mi store clone application",
        techStack: "react, javascript",
        link: "https://github.com/mkbhru/mi-store-clone",
      },
      "Lottery System Dapp": {
        desc:
          "A lottery system dapp built using remix, ethereum, solidity, metamask.",
        techStack: "Solidity, remix, js",
        link: "https://github.com/mkbhru/lottery-system-dapp",
        open: "",
      },
      "Ocr reader Flutter": {
        desc:
          "project for hackathon: using Flutter Mobile Vision library we have made a QR code scanner which also tells how safe the url is using vulnerability data of firefox.",
        techStack: "Java, Android Studio",
        link: "https://github.com/mkbhru/ocr-reader-flutter",
        open: "",
      },
    };

    return (
      <div id="projects">
        <div className="section-header ">
          <span className="section-title">/ pet projects</span>
        </div>
        {window.innerWidth > 999 && (
          <Carousel>
            {Object.keys(spotlightProjects).map((key, i) => (
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={spotlightProjects[key]["image"]}
                  alt={key}
                />
                <div className="caption-bg">
                  <Carousel.Caption>
                    <h3>{spotlightProjects[key]["title"]}</h3>
                    <p>
                      {spotlightProjects[key]["desc"]}
                      <p className="techStack">
                        {spotlightProjects[key]["techStack"]}
                      </p>
                    </p>
                    <ExternalLinks
                      githubLink={spotlightProjects[key]["link"]}
                      openLink={spotlightProjects[key]["open"]}
                    ></ExternalLinks>
                  </Carousel.Caption>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        )}
        <div className="project-container">
          <ul className="projects-grid">
            {Object.keys(projects).map((key, i) => (
              <FadeInSection delay={`${i + 1}00ms`}>
                <li className="projects-card">
                  <div className="card-header">
                    <div className="folder-icon">
                      <FolderOpenRoundedIcon
                        style={{ fontSize: 35 }}
                      ></FolderOpenRoundedIcon>
                    </div>
                    <ExternalLinks
                      githubLink={projects[key]["link"]}
                      openLink={projects[key]["open"]}
                    ></ExternalLinks>
                  </div>

                  <div className="card-title">{key}</div>
                  <div className="card-desc">{projects[key]["desc"]}</div>
                  <div className="card-tech">{projects[key]["techStack"]}</div>
                </li>
              </FadeInSection>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Projects;
