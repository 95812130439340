import React from "react";
import { ReactP5Wrapper } from "react-p5-wrapper";
import "../styles/Bc.css"

const Sketch = (p5) => {
  let angle = 0;
  let rotationSpeed = 0.01;
  let blockPositions = [];
  let zoom = 1;

  p5.setup = () => {
    p5.createCanvas(400, 400, p5.WEBGL);
    // p5.background(85, 204, 38);
    p5.stroke(8, 8, 109);
  };

  p5.draw = () => {
    p5.background(10, 25, 47);
    p5.fill(10, 25, 47);
    p5.stroke(0, 178, 193);
    p5.strokeWeight(3);
    // Apply zoom effect
    zoom = p5.map(p5.sin(p5.frameCount * 0.02), -1, 1, 0.5, 1.3);
    p5.scale(zoom);

    p5.rotateX(p5.PI / 4);
    p5.rotateY(angle);
    p5.rotateZ(p5.PI / 4);

    blockPositions = [];
    drawBlockchain(-100, 0, 0);
    drawBlockchain(0, 0, 0);
    drawBlockchain(100, 0, 0);

    drawConnections();

    angle += rotationSpeed;
  };

  function drawBlockchain(x, y, z) {
    p5.push();
    p5.translate(x, y, z);
    p5.box(50);
    blockPositions.push({ x, y, z });
    p5.pop();
  }

  function drawConnections() {
    // p5.stroke(25);
    for (let i = 0; i < blockPositions.length - 1; i++) {
      const startPos = blockPositions[i];
      const endPos = blockPositions[i + 1];
      p5.line(startPos.x, startPos.y, startPos.z, endPos.x, endPos.y, endPos.z);
    }
  }
};

const BlockchainAni = () => (
  <div id="blockchain" style={{ textAlign: "center", margin: "20px" }}>
    <ReactP5Wrapper sketch={Sketch} />
  </div>
);

export default BlockchainAni;
